import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'
import {
  ArrowIcon,
  Column,
  DatePicker,
  Header,
  RadioButtonItem,
  Row,
  ShadowBox,
  Text,
  TextLink,
} from '@mattilsynet/mt-ui'
import { ModalWrapper } from '../modal-wrapper'

const StyledDateColumn = styled(Column)<{ isTinyScreen: boolean }>`
  & {
    min-width: ${({ isTinyScreen }) => (isTinyScreen ? '135px' : '250px')};
    min-height: 70px;
  }
`

export const DatoFilterModal = ({
  isOpen,
  onCancel,
  sisteTilsynDate,
  setSisteTilsynDate,
}: {
  isOpen: boolean
  onCancel: () => void
  sisteTilsynDate: string
  setSisteTilsynDate: (date: string) => void
}) => {
  const isTinyScreen = useMediaQuery({ query: '(max-width: 767px)' })
  const calendarRef: { [key: string]: any } = useMemo(() => [], [])
  const today = dayjs().format('YYYY-MM-DD')
  const oneMonthAgo = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
  const sixMonthsAgo = dayjs().subtract(6, 'month').format('YYYY-MM-DD')
  const oneYearAgo = dayjs().subtract(1, 'year').format('YYYY-MM-DD')

  const renderDatePicker = (
    label: string,
    date: string | null,
    setDate: (date: string) => void,
    isFromDate: boolean
  ) => {
    return (
      <StyledDateColumn
        padding={isTinyScreen ? 1.5 : 3}
        spacing={1}
        isTinyScreen={isTinyScreen}
      >
        <Header as="h2">{label}</Header>
        <Row spacing={1} align="center">
          <DatePicker
            date={date || undefined}
            setDate={(d) => setDate(dayjs(d).format('YYYY-MM-DD'))}
            ariaLabel={label}
            minDate={
              !isFromDate && sisteTilsynDate
                ? dayjs(sisteTilsynDate).format('YYYY-MM-DD')
                : undefined
            }
            ref={(ref) => (calendarRef[label] = ref)}
          />
          {date ? (
            <Text>{dayjs(date).format('D. MMM YYYY')}</Text>
          ) : (
            <Row
              onClick={() => {
                if (calendarRef[label]) {
                  calendarRef[label].setOpen(true)
                }
              }}
            >
              <Text weight="light">Velg dato</Text>
            </Row>
          )}
        </Row>
      </StyledDateColumn>
    )
  }

  return (
    <ModalWrapper
      isOpen={isOpen}
      onCancel={onCancel}
      title="Dato"
      buttonOkText="Bruk valgte filter"
      onOk={() => {
        setSisteTilsynDate(
          sisteTilsynDate
        )
        onCancel()
      }}
    >
      <Column spacing={3} padding={isTinyScreen ? 0 : 1}>
        <Row justify="flex-end" padding={[1, 2]}>
          <TextLink
            small
            onClick={() => {
              setSisteTilsynDate(null)
            }}
          >
            Fjern valgt dato
          </TextLink>
        </Row>
        <Row justify="space-between" align="center">
          <ShadowBox>
            {renderDatePicker('Fra dato', sisteTilsynDate, setSisteTilsynDate, true)}
          </ShadowBox>
          <ArrowIcon rotateDirection="RIGHT" size="medium" />
        </Row>

        <ul>
          <RadioButtonItem
            id="oneMonthAgo"
            onClick={() => {
              setSisteTilsynDate(oneMonthAgo)
            }}
            selected={sisteTilsynDate === oneMonthAgo}
          >
            Siste måned
          </RadioButtonItem>
          <RadioButtonItem
            id="sixMonthsAgo"
            onClick={() => {
              setSisteTilsynDate(sixMonthsAgo)
            }}
            selected={sisteTilsynDate === sixMonthsAgo}
          >
            Siste 6 måneder
          </RadioButtonItem>
          <RadioButtonItem
            id="oneYearAgo"
            onClick={() => {
              setSisteTilsynDate(oneYearAgo)
            }}
            selected={sisteTilsynDate === oneYearAgo}
          >
            Siste år
          </RadioButtonItem>
        </ul>
      </Column>
    </ModalWrapper>
  )
}
