import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { IStoreState } from '../reducers/types'
import { DependencyList, EffectCallback, useEffect, useMemo, useRef, useState } from 'react'
import * as R from 'ramda'
import history from '../history'

export const useTypedSelector: TypedUseSelectorHook<IStoreState> = useSelector

export const useDebounce = <T>(value: T, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(() => {
      const timeOut = setTimeout(() => {
        setDebouncedValue(value)
      }, delay)
      return () => clearTimeout(timeOut)
    }, [delay, value])
    return debouncedValue
  }

  export const useDeepEffect = (
    effectCallback: EffectCallback,
    deps: DependencyList
  ) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effectCallback, useDeepCompareMemoize(deps))
  }

  const useDeepCompareMemoize = (deps) => {
    const ref = useRef()
  
    if (!R.equals(deps, ref.current)) {
      ref.current = deps
    }
  
    return ref.current
  }

  export const usePaginatedList = <T>(
    data: T[],
    pageSize: number
  ): [
    currentPage: T[],
    totalPages: number,
    pageNumber: number,
    setPage: React.Dispatch<React.SetStateAction<number>>
  ] => {
    const [page, setPage] = useState(0)
  
    const paginatedList = useMemo(
      () =>
        data.reduce((res, item, index) => {
          const page = Math.floor(index / pageSize)
          res[page] = [...(res[page] ?? []), item]
          return res
        }, [] as T[][]),
      [data, pageSize]
    )
  
    const currentPage = useMemo(
      () => paginatedList[page] ?? [],
      [page, paginatedList]
    )
  
    useDeepEffect(() => setPage(0), [data])
  
    return [currentPage, paginatedList.length, page, setPage]
  }

  export const useActionOnBackButton = (
    actionOnBackButton: () => void,
    isActive: boolean
  ) => {
    return useEffect(() => {
      if (isActive) {
        return history.block((_location, action) => {
          if (action === 'POP') {
            actionOnBackButton?.()
            return false
          }
        })
      }
    }, [isActive, actionOnBackButton])
  }