import { Table } from "@mattilsynet/mt-ui"
import { IThemeColors } from "@mattilsynet/mt-ui/dist/assets/colors"
import styled from "styled-components"


export const StyledRiskScoringTable = styled(Table)<{
    theme: IThemeColors
}>`
table > tbody > .data-row.show-more-row {
  padding: 0;
}
table > tbody > .data-row.show-more-row .show-more-content {
  padding: 0;
}
.bg-white {
  background-color: ${({ theme }) => theme.white};
}
.bg-gray {
  background-color: ${({ theme }) => theme.gray7};
}
.show-more-content {
  border-top: 1px solid ${({ theme }) => theme.gray4};
}
.show-more-content .addresse-box {
  max-width: 400px;
  padding-bottom: 15px;
}
.show-more-content .funn-box {
  max-width: 500px;
}
.show-more-content .oppfoelging-box {
  min-width: 300px;
}
.show-details {
  float: right;
  margin-top: 0px;
  padding-top: 0px;
  height: 0px;
}
`