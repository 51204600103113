import { goBack as routerGoBack, push } from 'connected-react-router'
import { createQueryStringFromObject } from '@mattilsynet/mt-common/lib/utils/createQueryStringFromObject'

export const goBack = (fallBackPath = '/') => {
  const fromPath = window?.history?.state?.state?.fromPath
  if (fromPath) {
    return routerGoBack()
  }
  return push(fallBackPath)
}

export const pushState = (path: string) =>
  push(path, { fromPath: window?.location?.pathname })

export const pushQuery = (query: string) =>
  push(`${window?.location?.pathname}?${query}`, {
    fromPath: window?.location?.pathname,
  })

export const replaceUrlQuery = (query: string) =>
  window.history.replaceState(
    null,
    '',
    `${window?.location?.pathname}?${query}`
  )

  export const pushStateWithSource = (
    path: string,
    extraParams?: { [key: string]: string }
  ) => {
    const sourceQuery = createQueryStringFromObject('?')({
      ...(extraParams || {}),
    })
    const query = createQueryStringFromObject('?')({
      source: `${window?.location?.pathname}${sourceQuery}`,
    })
    return push(`${path}${query}`, { fromPath: window?.location?.pathname })
  }