import React, { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { IHeader } from '@mattilsynet/mt-ui/dist/components/table'
import {
  Button,
  Column,
  ExpandArrow,
  Row,
  TableCell,
  TableRow,
  TextLink,
  Text,
  LoadingDots
} from '@mattilsynet/mt-ui'
import dayjs from 'dayjs'
import { pushStateWithSource } from '../../common/common-router'
import { TilsynobjektHistorikkState, Tilsynsobjekt } from '../../model/type'
import { planTilsynAction, planTilsynFail, planTilsynOk } from '../../actions/tilsynActions'
import { planTilsyn } from '../../api/tilsyn'
import { IStoreState } from '../../reducers/types'
import { toast } from '@mattilsynet/mt-common'
import { tilsynPlannedError, tilsynPlannedSuccess } from '../../common/toast'
import classNames from 'classnames'
import { VirksomhetAdresseInfo } from '../virksomhet-address-info'

export const TilsynsobjektRow = ({
  index,
  tilsynsobjekt,
  openRows,
  toggleOpenRow,
  tableHeader,
  onFordelTilKollega
  // allowedStatuses,
  // allowedStatusBegrunnelser,
  // accessType,
}: {
  index: number
  tilsynsobjekt: Tilsynsobjekt
  openRows: TilsynobjektHistorikkState['openRows']
  toggleOpenRow: (tilsynsObjektId?: string) => void
  tableHeader: IHeader[]
  onFordelTilKollega: (tilsynsobjektId: string) => void
  // allowedStatuses: IMeldingState['allowedStatuses']
  // allowedStatusBegrunnelser: IMeldingState['allowedStatusBegrunnelser']
  // accessType: IMeldingsHistorikkState['accessType']
}) => {
  const dispatch = useDispatch()
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1023px)' })
  // const fordeltTilOrgenhet = useTypedSelector(
  //   orgenhetSelectors.getOrgenhetByUsername(tilsynsobjekt.vurdering?.fordeltTil || '')
  // )
  const [isFordelTilModalOpen, setIsFordelTilModalOpen] = useState(false)
  // const fordeltTil = fordeltTilOrgenhet?.data?.name
  // const tilsynsobjekt = tilsynsobjekt?.tilsynsobjekt
  const showMore = openRows.some((id) => id === tilsynsobjekt.tilsynsobjektId)
  // const observasjoner = tilsynsobjekt.funnList
  //   ?.map?.((funn) => funn.observasjoner)
  //   .flat()
  const backgroundColor = index % 2 === 0 ? 'bg-gray' : 'bg-white'
  // const [onMount, setOnMount] = useState(true)
  // const scrollToMeldingId = new URLSearchParams(location.search).get(
  //   'scrollToMeldingId'
  // )

  const goToMelding = (meldingId: string) => {
    dispatch(
      pushStateWithSource(`/melding/${meldingId}`, {
        scrollToMeldingId: meldingId,
      })
    )
  }

  const { accessToken } = useSelector(
    (state: IStoreState) => state.user
)

const { username } = useSelector(
    (state: IStoreState) => state.user.userOrgenhet
)

const { tilsynLoading, tilsynPlanned } = useSelector(
  (state: IStoreState) => state.tilsyn
)

const { sisteTilsyn, sisteTilsynFail, sisteTilsynLoading, sisteTilsynOk } = useSelector(
  (state: IStoreState) => state.sisteTilsyn
)

const { addresses, addressLoading } = useSelector(
  (state: IStoreState) => state.address
)

const getAddress = () => {
  return addresses.some(t => t.tilsynsobjektId == tilsynsobjekt.tilsynsobjektId) ? 
  addresses.filter(t => t.tilsynsobjektId == tilsynsobjekt.tilsynsobjektId)[0] : 
  null;
}

const isAddressLoading = () => {
  return addressLoading.some(t => t == tilsynsobjekt.tilsynsobjektId);
}

const renderFordelSection = () => {
  return <Column
        spacing={1}
        padding={isSmallScreen ? [3, 3] : [3, 3, 3, 0]}
        className="oppfoelging-box"
      >
        <Column spacing={1} padding={[2, 0, 0, 0]}>
          {tilsynLoading.filter(t => t == tilsynsobjekt.tilsynsobjektId).length > 0 ? 
          <div className='confirmed-tilsyn'><LoadingDots /></div> : tilsynPlanned.filter(t => t == tilsynsobjekt.tilsynsobjektId).length > 0 ? 
          <div className='confirmed-tilsyn'>Tilsynskvittering opprettet</div> :
            <>
              <Button 
                secondary 
                onClick={() => {
                  dispatch(planTilsynAction(tilsynsobjekt.tilsynsobjektId));
                  planTilsyn(
                    tilsynsobjekt.tilsynsobjektId, 
                    username, 
                    accessToken,
                    () => {
                      dispatch(planTilsynOk(tilsynsobjekt.tilsynsobjektId));
                      dispatch(toast.actions.showToast(tilsynPlannedSuccess()))
                    },
                    () => {
                      dispatch(planTilsynFail(tilsynsobjekt.tilsynsobjektId));
                      dispatch(toast.actions.showToast(tilsynPlannedError()))
                    }
                  );
              }}
              >
                Opprett tilsynskvittering til deg
              </Button>
              <Button
                secondary
                onClick={() => onFordelTilKollega(tilsynsobjekt.tilsynsobjektId)}
              >
                Opprett tilsynskvittering til kollega
              </Button>
            </>  }
        </Column>
      </Column>
}

const renderDate = (tilsynsobjektId: string) => {

  const t = sisteTilsyn.filter(t => t.tilsynsobjektId == tilsynsobjektId)[0];

  if(t == null) {
    return '';
  }

  if(t.tilsynDate == null || t.tilsynDate == '') {
    return '';
  }

  return dayjs(
    t.tilsynDate
  ).format('D. MMM YYYY');
}

  return (
    <React.Fragment key={tilsynsobjekt.tilsynsobjektId}>
      <TableRow
        key={tilsynsobjekt.tilsynsobjektId}
        header={tableHeader.map((item) => String(item.display))}
        className={backgroundColor}
      >
        <TableCell>
          {tilsynsobjekt.riskScoring.toFixed(2)}
        </TableCell>
        <TableCell>
          {sisteTilsynLoading.includes(tilsynsobjekt.tilsynsobjektId) ? <LoadingDots /> : 
            sisteTilsynOk.includes(tilsynsobjekt.tilsynsobjektId) ?
            renderDate(tilsynsobjekt.tilsynsobjektId) : '' }
        </TableCell>
        <TableCell>
          {tilsynsobjekt?.name || ''}
        </TableCell>
        <TableCell>
          {tilsynsobjekt.avdeling || ''}
        </TableCell>

        <TableCell className='show-details'>
          <Row>
            <TextLink onClick={() => toggleOpenRow(tilsynsobjekt.tilsynsobjektId)} small>
              {showMore ? 'Skjul detaljer' : 'Vis detaljer'}
            </TextLink>
            <ExpandArrow
              rotateDirection={showMore ? 'UP' : 'DOWN'}
              size="medium"
              onClick={() => toggleOpenRow(tilsynsobjekt.tilsynsobjektId)}
              cursor="pointer"
            />
          </Row>
        </TableCell>
      </TableRow>
      {showMore && (
       <TableRow
        className={classNames(backgroundColor, 'show-more-row')}
        header={[' ', ' ', ' ']}
      >
        <TableCell colSpan={3} className="show-more-content">
          <Column
            spacing={4}
            padding={[3, 3, 0, 3]}
            className="addresse-box"
          >
            <Column spacing={2}>
              { isAddressLoading() ? 
              <LoadingDots /> :
              <VirksomhetAdresseInfo
                tittel="Beliggenhetsadresse"
                adresse={getAddress()}
              /> }
            </Column>
            <Column spacing={2}>
              <Text as="h3" weight="bold" size="heading3">
                Tilhørighet
              </Text>
              <Text as="p">
                {tilsynsobjekt.avdeling}
              </Text>
            </Column>
            <Column spacing={2}>
              <Text as="h3" weight="bold" size="heading3">
                Produksjonsformer
              </Text>
              <Text as="p">
                N/A
              </Text>
            </Column>
          </Column>
        </TableCell>
        <TableCell
          colSpan={1}
          className={classNames({ 'show-more-content': !isSmallScreen })}
        >
          <Column
            spacing={1}
            padding={isSmallScreen ? [3, 3, 0, 3] : [3, 3, 0, 0]}
          >
            <Text as="h4" weight="bold">
              Tidligere tilsynskvitteringer
            </Text>
            <Column className="funn-box">
              - N/A
            </Column>
          </Column>
        </TableCell>
        <TableCell
          colSpan={1}
          className={classNames({ 'show-more-content': !isSmallScreen })}
        >
          {renderFordelSection()}
        </TableCell>
      </TableRow>
      )}

      {/* {isFordelTilModalOpen && (
        <FordelTilModal
          onCancel={() => setIsFordelTilModalOpen(false)}
          isOpen={isFordelTilModalOpen}
          accessType={accessType}
          vurdering={tilsynsobjekt.vurdering}
          meldingId={tilsynsobjekt.meldingId}
        />
      )} */}
    </React.Fragment>
  )
}
