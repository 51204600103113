import React, { ComponentProps, useState } from 'react'
import dayjs from 'dayjs'
import { useMediaQuery } from 'react-responsive'
import {
  Accordion,
  Column,
  Row,
  SelectableButton,
  ShadowBox,
  TextLink,
} from '@mattilsynet/mt-ui'
import { FilterAccordionHeader } from './filter-accordion-header'
import { PageMetadata } from '../../model/type'
import { DatoFilterModal } from './dato-filter-modal'
import { SelectedFilter } from './SelectedFilter'

export const Filtrering = ({
  pageMetaData,
  filterSisteTilsynDate,
  setFilterSistetilsynDate,
  currentTotalShown
}: {
  pageMetaData: PageMetadata,
  filterSisteTilsynDate,
  setFilterSistetilsynDate,
  filterOngoingTilsynskvittering,
  setFilterOngoingTilsynskvittering,
  currentTotalShown
}) => {
  const isTinyScreen = useMediaQuery({ query: '(max-width: 767px)' })

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const [isDatoFilterModalOpen, setIsDatoFilterModalOpen] =
    useState<boolean>(false)

  let antallMeldingerText = ''
  if (
    pageMetaData.size != null &&
    pageMetaData.number != null &&
    pageMetaData.totalElements != null
  ) {
    let antallMeldingerSomVises = pageMetaData.size * (pageMetaData.number + 1)
    const totaltAntallMeldinger = pageMetaData.totalElements
    if (totaltAntallMeldinger < antallMeldingerSomVises) {
      antallMeldingerSomVises = totaltAntallMeldinger
    }
    antallMeldingerText = `Viser ${currentTotalShown()} av ${pageMetaData.totalElements} tilsynsobjekter`
  }

  const RowOrColumn = (
    props: ComponentProps<typeof Column> | ComponentProps<typeof Row>
  ) => (isTinyScreen ? <Column {...props} /> : <Row {...props} />)

  return (
    <ShadowBox>
      <Accordion
        small
        open={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        header={
          <FilterAccordionHeader
            isFiltered={false}
            antallMeldingerText={antallMeldingerText}
          />
        }
      >
        <Column spacing={1}>
          <RowOrColumn spacing={1} >
            Ikke vis tilsynsobjekt hvor det har vært gjennomført tilsyn etter:
          </RowOrColumn>
          <RowOrColumn spacing={1}>

            <SelectableButton
              withPadding
              onClick={() => setIsDatoFilterModalOpen(true)}
            >
              Dato
            </SelectableButton>
           
          </RowOrColumn> 
          <RowOrColumn spacing={1}>

          </RowOrColumn>

          <Row wrap>
            {filterSisteTilsynDate && (
              <SelectedFilter
                marginRight
                onClickRemove={() =>
                  setFilterSistetilsynDate(undefined)
                }
              >
                {`Etter: ${dayjs(filterSisteTilsynDate).format(
                  'D. MMM YYYY'
                )}`}
              </SelectedFilter>
            )}
          </Row>
        </Column>
        
        <Row justify="flex-end" margin={[1, 1]}>
          <TextLink
            small
            onClick={() => 
              setFilterSistetilsynDate(undefined)
            }
          >
            Fjern alle filtre
          </TextLink>
        </Row>
      </Accordion>

      {isDatoFilterModalOpen && (
        <DatoFilterModal
          isOpen={isDatoFilterModalOpen}
          onCancel={() => setIsDatoFilterModalOpen(false)}
          sisteTilsynDate={filterSisteTilsynDate}
          setSisteTilsynDate={setFilterSistetilsynDate
          }
        />
      )}
    </ShadowBox>
  )
}
