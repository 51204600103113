import {
  applyMiddleware,
  combineReducers,
  compose as reduxCompose,
  createStore,
  Store,
} from 'redux'

import thunk from "redux-thunk"

import epics from '../epics'
import { createEpicMiddleware } from 'redux-observable'

// Reducer
import { IStoreState } from './types'
import userReducer from './userReducer'
import diagnosticReducer from './diagnosticReducer'
import uiReducer from './uiReducer'

// External
import { toast, notifications } from '@mattilsynet/mt-common'

// Token sync
import { authSubject } from '../auth/keycloak'
import { updateToken, userExpired, userSignedOut } from '../actions/userActions'
import svineholdReducer from './riskScoringReducer'
import tilsynReducer from './tilsynReducer'
import tilsynsobjektRowReducer from './tilsynsobjektRowReducer'
import addressReducer from './addressReducer'
import ansattReducer from './ansattReducer'
import sisteTilsynReducer from './sisteTilsynReducer'

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    // eslint-disable-next-line no-undef
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof reduxCompose
  }
}

// Redux
const epicMiddleware = createEpicMiddleware()
const composeEnhancers =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose

export const rootReducer = combineReducers({
  diagnostic: diagnosticReducer,
  svinehold: svineholdReducer,
  tilsyn: tilsynReducer,
  address: addressReducer,
  ansatte: ansattReducer,
  sisteTilsyn: sisteTilsynReducer,
  openRows: tilsynsobjektRowReducer,
  user: userReducer,
  ui: uiReducer,
  ...notifications.reducer,
  ...toast.reducer,
})

const enhancers = composeEnhancers(applyMiddleware(epicMiddleware, thunk))
const store: Store<IStoreState> = createStore(rootReducer, enhancers)
epicMiddleware.run(epics)

// Sync token with redux-store
authSubject.subscribe((keycloak) => {
  keycloak.addListener('authSuccess', ({ accessToken }) => {
    store.dispatch(updateToken(accessToken))
  })

  keycloak.addListener('refreshSuccess', ({ accessToken }) => {
    store.dispatch(updateToken(accessToken))
  })

  keycloak.addListener('refreshError', () => {
    store.dispatch(userExpired())
  })

  keycloak.addListener('logout', () => {
    store.dispatch(userSignedOut())
  })

  keycloak.addListener('tokenExpired', () => {
    keycloak.updateToken()
  })
})

export default store
