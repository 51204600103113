import { useContext, useEffect, useState } from "react";
import { Tilsynsobjekt, RiskScoringMetaData, IVirksomhetAdresse, PageMetadata } from '../../model/type'
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { Button, LoadingSpinner, Row, ShadowBox, ThemeContext } from "@mattilsynet/mt-ui";
// import { useNavigate } from 'react-router-dom';

import { loadRiskScoringData } from "../../stores/riskScoringStore/actionCreators";
import React from "react";
import { Dispatch } from "redux";
import { loadingRiskScoringData } from "../../actions/riskscoringActions";
import { IStoreState } from "../../reducers/types";
import { StyledRiskScoringTable } from "../styledriskscoringtable/StyledRiskScoringTable";
import { getSortableTableHeader } from "../../common/sorting";
import { TilsynsobjektRow } from "../riskscored-tilsynsobjekt-row";
import { closeRowAction, openRowAction } from "../../actions/tilsynsobjektRowActions";
import { fetchVirksomhetsAddress, loadAddressFail, loadAddressOk } from "../../actions/addressActions";
import { fetchAddress } from "../../api/address";
import { FordelTilsynTilModal } from "../../modals/fordel-til-kollega-modal";
import { planTilsynAction, planTilsynFail, planTilsynOk } from "../../actions/tilsynActions";
import { getSisteTilsyn, planTilsyn } from "../../api/tilsyn";
import { toast } from "@mattilsynet/mt-common";
import { tilsynPlannedError, noRiskScoringWithinArea, tilsynPlannedSuccess } from "../../common/toast";
import { loadSisteTilsyn, loadSisteTilsynFail, loadSisteTilsynOk } from "../../actions/sisteTilsynActions";
import { Filtrering } from "../filtrering";
import { filterRiskScoringBySisteTilsyn } from "../../utils/utils";

const RiskScoringTable = ({
    fetchData,
  }: {
    fetchData
  }) => {

    const [filterSisteTilsynDate, setFilterSisteTilsynDate] = useState<string>(undefined);
    const [filterOngoingTilsynskvittering, setFilterOngoingTilsynskvittering] = useState<boolean>(false);

    const dispatch: Dispatch<any> = useDispatch();
    const theme = useContext(ThemeContext)

    const tilsynsobjekter: readonly Tilsynsobjekt[] = useSelector(
        (state: IStoreState) => state.svinehold.tilsynsobjekter ?? [],
        shallowEqual
    );

    const { loading, initialized } = useSelector(
        (state: IStoreState) => state.svinehold,
        shallowEqual
    );

    const { accessToken } = useSelector(
        (state: IStoreState) => state.user
    )

    const currentTotalShown = React.useCallback(() => {
        return tilsynsobjekter.filter(t => 
            filterRiskScoringBySisteTilsyn(t, sisteTilsyn, filterSisteTilsynDate)).length
    }, [tilsynsobjekter, filterSisteTilsynDate])

    const loadData = React.useCallback(
        (riskScoringMetaData: RiskScoringMetaData, page: PageMetadata) => {

            dispatch(loadRiskScoringData(riskScoringMetaData ? riskScoringMetaData.riskScoringDataList : [], page));

            if(page.totalElements < 1) {
                dispatch(toast.actions.showToast(noRiskScoringWithinArea()))
            }

            const tilsynsobjektIds = riskScoringMetaData.riskScoringDataList.map(t => t.tilsynsobjektId);

            dispatch(loadSisteTilsyn(tilsynsobjektIds));

            getSisteTilsyn(
                accessToken,
                tilsynsobjektIds,
                (data: any) => {
                    dispatch(loadSisteTilsynOk(data));
                },
                () => {
                    dispatch(loadSisteTilsynFail(tilsynsobjektIds));
                }
            );
        },
        []
    )

    const { pageMetaData } = useSelector(
        (state: IStoreState) => state.svinehold
    )

    const { openRows } = useSelector(
        (state: IStoreState) => state.openRows
    )

    const { addressOk } = useSelector(
        (state: IStoreState) => state.address
    )

    const { sisteTilsyn } = useSelector(
        (state: IStoreState) => state.sisteTilsyn
      )

    const loadAddressOnOpen = (tilsynsobjektId: string): boolean => {
        return !addressOk.some(t => t == tilsynsobjektId);
    }

    const toggleOpenRow = (tilsynsobjektId) => {        
        if(openRows.some((id) => id === tilsynsobjektId)) {
            closeRow(tilsynsobjektId);
        }  else {
            openRow(tilsynsobjektId);
        }
    }

    const getOrgNummer = (tilsynsobjektId: string) => {
        return tilsynsobjekter.filter(t => t.tilsynsobjektId == tilsynsobjektId)[0].orgnummer;
    }

    const openRow = (tilsynsobjektId: string) => {

        if(tilsynsobjekter.filter(t => t.tilsynsobjektId == tilsynsobjektId && loadAddressOnOpen(tilsynsobjektId)).length > 0) {
            dispatch(fetchVirksomhetsAddress(tilsynsobjektId));

            fetchAddress(
                accessToken,
                getOrgNummer(tilsynsobjektId),
                (address: IVirksomhetAdresse) => {
                    dispatch(loadAddressOk(tilsynsobjektId, address));
                },
                () =>  {
                    dispatch(loadAddressFail(tilsynsobjektId));
                }
            );
        }

        dispatch(openRowAction(tilsynsobjektId));
    }

    const closeRow = (tilsynsobjektId: string) => {
        dispatch(closeRowAction(tilsynsobjektId));
    }

    const fetchNextData = () =>  {
        dispatch(loadingRiskScoringData());
        fetchData(
            accessToken,
            pageMetaData,
            loadData,
        );
    }

    useEffect(() => {
        fetchNextData();
    }, [])

    const [sortColumn, setSortColumn] = useState('sistRedigertDayjs')
    const [sortArrow, setSortArrow] = useState<'DOWN' | 'UP'>('DOWN')
    
    const tableHeader = getSortableTableHeader(
        [
            { display: 'Risiko' },
            { display: 'Siste tilsyn' },
            { display: 'Virksomhetsnavn' },
            { display: 'MT kontor' },
            { display: ' ' },
        ],
        sortColumn,
        sortArrow,
        setSortColumn,
        setSortArrow
    )

    const [isFordelTilsynModelOpen, setIsFordelTilsynModalOpen] =
    useState(false)

    const [selectedTilsynsobjekt, setSelectedTilsynsobjekt] = useState(
        null
    )

    const onOpenFordelTilKollegaModal = (tilsynsobjektId: string) => {
        setIsFordelTilsynModalOpen(true)
        setSelectedTilsynsobjekt(tilsynsobjektId)
    }

    const fordelTilsyn = (name: string, username: string, tilsynsobjektId: string) => {
        dispatch(planTilsynAction(tilsynsobjektId));
        planTilsyn(
          tilsynsobjektId, 
          username, 
          accessToken,
          () => {
            dispatch(planTilsynOk(tilsynsobjektId));
            dispatch(toast.actions.showToast(tilsynPlannedSuccess()))
            setIsFordelTilsynModalOpen(false)
          },
          () => {
            dispatch(planTilsynFail(tilsynsobjektId));
            dispatch(toast.actions.showToast(tilsynPlannedError()))
          }
        );
    }

    const renderContent = () => {
        return loading && !initialized ?
            <LoadingSpinner title="Laster inn riskscoring" /> :
            <>
                <StyledRiskScoringTable header={tableHeader} theme={theme}>
                    {tilsynsobjekter
                        .filter(t => filterRiskScoringBySisteTilsyn(t, sisteTilsyn, filterSisteTilsynDate))
                        .map((tilsynsobjekt: Tilsynsobjekt, index: number) => (
                            <TilsynsobjektRow
                                key={tilsynsobjekt.tilsynsobjektId}
                                index={index}
                                tilsynsobjekt={tilsynsobjekt}
                                openRows={openRows}
                                toggleOpenRow={toggleOpenRow}
                                tableHeader={tableHeader}
                                onFordelTilKollega={onOpenFordelTilKollegaModal}
                            />
                    ))}
                </StyledRiskScoringTable>

                {pageMetaData.number !== undefined &&
                    pageMetaData.totalPages !== undefined &&
                    pageMetaData?.number < pageMetaData?.totalPages - 1 && (
                    <Row justify="center" padding={1}>
                        <Button onClick={() => fetchNextData()} loading={loading}>
                        Last inn neste 50 tilsynsobjekter
                        </Button>
                    </Row>
                )}

                {isFordelTilsynModelOpen && (
                    <FordelTilsynTilModal
                        isOpen={isFordelTilsynModelOpen}
                        onCancel={() => setIsFordelTilsynModalOpen(false)}
                        tilsynsobjektId={selectedTilsynsobjekt}
                        onSelect={fordelTilsyn}
                    />
                )}
            </>
    }

    return (
        <>
            <Filtrering 
                pageMetaData={pageMetaData} 
                filterSisteTilsynDate={filterSisteTilsynDate}
                setFilterSistetilsynDate={setFilterSisteTilsynDate}
                filterOngoingTilsynskvittering={filterOngoingTilsynskvittering}
                setFilterOngoingTilsynskvittering={setFilterOngoingTilsynskvittering}
                currentTotalShown={currentTotalShown}
            />
            
            <ShadowBox>{renderContent()}</ShadowBox>
        </>
      )
}

export default RiskScoringTable;